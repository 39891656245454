import basePageFields from '@fragments/pages/basePageFields';

import ImageSummaryFragment from '@fragments/summaries/ImageSummaryFragment';
import PersonSummaryFragment from '@fragments/summaries/PersonSummaryFragment';
import CenterSummaryFragment from '@fragments/summaries/CenterSummaryFragment';
import TagFragment from '@fragments/pages/TagFragment';
import FileFragment from '@fragments/shared/FileFragment';
import StreamContainedFragment from '@fragments/shared/StreamContainedFragment';
import SeoFragment from '@fragments/shared/SeoFragment';
import SyndicationsFragment from '@fragments/shared/SyndicationsFragment';

export default gql`
    fragment ResearchFragment on Research {
        ${basePageFields}
        subtype
        parent {
            value {
                ...CenterSummaryFragment
            }
        }
        syndications {
            ...SyndicationsFragment
        }
        relatedContent
        contributors {
            ...PersonSummaryFragment
        }
        mainAuthors {
            ...PersonSummaryFragment
        }
        tags {
            ...TagFragment
        }
        publisherName
        dek
        eyebrow
        hero {
            image {
                ...ImageSummaryFragment
            }
        }
        streamContained {
            ...StreamContainedFragment
        }
        pdfResources {
            ...FileFragment
        }
        supplementalLinks {
            link {
                url
                newTab
                label
                type
                reference {
                    path
                }
                file {
                    ...FileFragment
                }
            }
        }
        primaryAssetLink {
            url
            label
            type
            file {
                ...FileFragment
            }
        }
        publicationDetails {
            cover {
                ...ImageSummaryFragment
            }
            chapters {
                id
                label
                authors {
                    ...PersonSummaryFragment
                }
                streamContained {
                    ...StreamContainedFragment
                }
            }
        }
        seo {
            ...SeoFragment
        }
    }
    ${SyndicationsFragment}
    ${ImageSummaryFragment}
    ${PersonSummaryFragment}
    ${CenterSummaryFragment}
    ${TagFragment}
    ${FileFragment}
    ${StreamContainedFragment}
    ${SeoFragment}
`;
